/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');*/
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --input-h: 2rem;
  --search-h: 2rem;
  --header-icon: 1.5rem;
  --padding: 1rem;
  --header-h: 3.5rem;
  --menu-c: 5rem;
  --menu-w: 14rem;
  --menu-item: 4rem;

  --workflow-card-height: 11.8rem;
  --workflow-head: 3rem;
  --worflow-background: #01194B07;

  --g-add: #70c07b;
  --g-chekbox: #6bd27c;
  --notif: #c91f47;

  --danger-0: #EA2E4805;
  --danger-1: #EA2E481C;
  --danger-5: #EA2E485C;
  --danger-8: #EA2E488C;
  --danger-c: #e83c55c5;
  --danger: #e83c55;

  --warning-1: #ecb1381c;
  --warning-8: #ecb1388c;
  --warning-c: #ecb138c5;
  --warning: #ecb138;

  --info-1: #42a6d01c;
  --info-8: #42a6d08c;
  --info-c: #42a6d0c5;
  --info: #42a6d0;

  --success-1: #41ad631c;
  --success-8: #41ad638c;
  --success-c: #41ad63c5;
  --success-e: #41ad63e5;
  --success: #41ad63;

  --toast-error: var(--danger);
  --toast-warning: #ecb138;
  --toast-info: #7799dd;
  --toast-success: var(--g-add);

  --toast-error-b: #ffdee3;
  --toast-warning-b: #fff3e2;
  --toast-info-b: #e9f0ff;
  --toast-success-b: #effff0;

  --mail-send: #ebf1ff;
  --mail-left: #ededed;

  --busy-time: #e3e3e89d;
  --lock: #bbbfcb;

  --text-9: #868f9d;
  --text-7: #727274;
  --text-6: #646466;
  --text-5: #535355;
  --text-3: #393b3f;

  --title-text-b: #7492b4;
  --title-text-9: #60799d;
  --title-text-7: #4e5d7e;
  --title-text-5: #3e4e67;
  --title-text-3: #2b394f;


  --light-text: #eee;

  --tooltip: #2b394f;
  --tooltip-text: #efe7e4;
  --light-color: #fff;
  --back-color-top: #fbfbfe;
  --back-color-top-1: #fbfbfe1d;
  --back-color-top-5: #fbfbfe5d;
  --back-color-top-8: #fbfbfe8d;
  --back-color-top-a: #fbfbfead;
  --back-color-top-sh: #f6f6f6;
  --back-color: #f3f3f3;
  --input-back: #f4f4f6;
  --link: #3b7fd9;

  --cancel-btn: #777EA815;
  --cancel-btn-hover: #777EA820;

  --border-0: #aaaaaa1a;
  --border-1: #aaaaaa2d;
  --border-2: #aaaaaa3d;
  --border-3: #aaaaaa4d;
  --border-4: #aaaaaa5d;
  --border-5: #aaaaaa6d;
  --border-6: #aaaaaa7d;
  --border-7: #aaaaaa8d;
  --border-8: #aaaaaa9d;
  --border-9: #aaaaaaad;
  --border-a: #999999bd;
  --border-b: #999999cd;
  --border-c: #888888dd;
  --border-d: #777777ed;
  --border-e: #696969ff;
  --border-f: #595959ff;
  --shadow-0: #00050909;
  --shadow-1: #00050919;
  --shadow-2: #00050929;
  --shadow-3: #00050939;
  --shadow-4: #00050949;
  --shadow-5: #00050959;
  --shadow-6: #00050969;
  --shadow-7: #00050979;
  --shadow-8: #00050989;
  --shadow-9: #00050999;
  --shadow-a: #000509a9;

  --accent-dark-a: #2045bda0;
  --accent-dark: #2045bd;
  --accent: #3d74ff;
  --accent-1: #3D74FF10;
  --accent-2: #3D74FF20;
  --accent-3: #3D74FF30;
  --accent-4: #3D74FF40;
  --accent-5: #3D74FF50;
  --accent-6: #3D74FF60;
  --accent-7: #3D74FF70;
  --accent-8: #3D74FF80;
  --accent-9: #3D74FF90;
  --accent-a: #3D74FFa0;
  --accent-b: #3D74FFb0;
  --accent-c: #3D74FFc0;
  --accent-e: #3D74FFe0;
  --accent-f: #3D74FFf0;
  --toast-color: #fff;
  --toast-text: #445;
}


/* ----------- RESET CSS ------------ */
* {
  -webkit-touch-callout: none!important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html {
  font-size: calc(11px + 0.4vw);
  color: var(--title-text-5);
}

::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}
::-webkit-scrollbar-track {
  background: #aaaaaa3d;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #aaaaaa5d;
  border-radius: 10px;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

h4 {
  font-weight: bold;
  margin: .5rem 0;
}

input, textarea, select {
  font-family: 'Roboto', Arial, sans-serif;
  appearance: none;
  outline: none!important;
  font-size: 1rem;
  border: none;
  background-color: unset;
  color: var(--title-text-5);
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  visibility: hidden;
}

textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

html, body {
  /*font-family: 'Montserrat', 'Roboto', Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
  /*font-family: 'Roboto', Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
  height: 100%;
  width: 100vw;
  overflow: hidden;
  background-color: var(--back-color);
  color: var(--text-5);
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
}

body {
  font-size: 1.125rem;
}

/* ------------ UTILS ------------- */
.text-7 {
  color: var(--title-text-7);
}
.text-9 {
  color: var(--title-text-9);
}

.text-b {
  color: var(--title-text-b);
}

.a-center, .a-center-rot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.a-center-rot {
  transform: translate(-50%, -50%) rotate(90deg);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full {
  width: 100%;
  height: 100%;
}

.row {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: font-weight .2s, font-size .2s, color .2s;
}

.screen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.a {
  text-decoration: underline;
  cursor: pointer;
  color: var(--link);
  flex-wrap: wrap;
}
.back-btn {
  position: relative;
  width: var(--input-h);
  height: var(--input-h);
  border-radius: 0.2rem;
  flex-shrink: 0;
  cursor: pointer;
}

@keyframes load {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.btn {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  box-shadow: inset 0 .1rem 0 0 #ffffff0d, inset 0 -.1rem 0 0 var(--shadow-3);
  /*box-shadow: inset 0 1px 0 0 var(--shadow-l-3), inset 0 -1px 0 0 var(--shadow-1);*/
  height: 2.4rem;
  line-height: 2.4rem;
  min-width: 2.4rem;
  text-align: center;
  padding: 0 var(--padding);
  border-radius: .6rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn-light {
  font-weight: 500;
}

.btn-green {
  background-color: var(--success-e);
  color: var(--light-color);
}

.btn-green:hover {
  background-color: var(--success);
}

.btn-primary {
  background-color: var(--accent-e);
  color: var(--light-color);
}
.btn-primary:hover {
  background-color: var(--accent);
}

.btn-cancel {
  background-color: var(--cancel-btn);
  color: var(--title-text-9);
}
.btn-cancel:hover {
  background-color: var(--cancel-btn-hover);
}

.btn-danger {
  background-color: var(--danger-c);
  color: var(--light-color);
}
.btn-danger:hover {
  background-color: var(--danger);
}

.shadow-btn {
  box-shadow: 0 0 0 1px var(--border-3);
  transition: box-shadow .2s, background-color .1s;
  background: var(--back-color-top);
  flex-shrink: 0;
  @apply rounded
}
.shadow-btn:hover {
  background: var(--light-color);
  box-shadow: 0 0 0 1px var(--border-1), 0 4px 6px -1px var(--shadow-2), 0 2px 4px -2px var(--shadow-2);
}
.shadow-btn:active {
  box-shadow: 0 0 0 10px var(--accent-1),0 0 0 1px var(--border-1), 0 4px 6px -1px var(--shadow-2), 0 2px 4px -2px var(--shadow-2);
}

.btn.disabled {
  opacity: .7;
  pointer-events: none;
}

.loading {
  position: relative;
  pointer-events: none;
  color: transparent;
}
.loading::after {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  animation: .8s load linear infinite;
  border: .2rem solid var(--light-color);
  border-top-color: transparent
}

.btn-cancel.loading::after {
  border-color: var(--title-text-9);
  border-top-color: transparent
}

.loading.accent::after {
  border-color: var(--accent-c);
  border-top-color: transparent
}

.loading.dark::after {
  border-color: var(--text-7);
  border-top-color: transparent
}

.with-tooltip {
  position: relative;
  width: fit-content;
}

.tooltip {
  position: absolute;
  top: calc(100% + .5rem);
  left: 50%;
  transform: translate(-50%, -10%) scale(.7);
  opacity: 0;
  visibility: hidden;
  background-color: var(--tooltip);
  width: max-content;
  max-width: 20rem;
  color: var(--tooltip-text);
  border-radius: .4rem;
  padding: .4rem .8rem;
  font-size: .9rem;
  line-height: 1.1rem;
  z-index: 2;
  font-weight: 400;
  text-transform: none;
  transition: .2s;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: calc(50% - .5rem);
  border-width: .5rem;
  border-style: solid;
  border-color: transparent transparent var(--tooltip) transparent
}

.with-tooltip:hover .tooltip, .tooltip.visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%);
}


/* ----------- INPUTS -------------- */
.input {
  cursor: text;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: .2rem;
  position: relative;
}
.input span {
  position: absolute;
  transition: .15s;
  top: 50%;
  cursor: text;
  left: .5rem;
  transform: translateY(-50%);
  font-size: 1rem;
}
.input:not(:has(span)) {
  margin-top: .2rem;
}


.input input, .input textarea, .input select {
  background: var(--input-back);
  -webkit-text-fill-color: var(--title-text-5)!important;
  border-radius: .3rem;
}


/* ---------- STYLES ---------- */

.avatar {
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffffcc;
  text-align: center;
  font-weight: bold;
}

.action-btn {
  width: 3rem;
  height: 3rem;
  background: var(--accent);
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  bottom: calc(var(--padding) * 2.5);
  right: calc(var(--padding) * 2);
  box-shadow: 0 8px 13px var(--accent-5);
  z-index: 2;
}

.action-btn> svg {
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 1;
}

.action-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--accent-dark);
  width: 2rem;
  height: 2rem;
}

.overlay {
  position: fixed;
  top: 0;
  transition: opacity .2s;
  width: 100vw;
  height: 100vh;
  background: var(--shadow-4);
  opacity: 0;
}
.overlay.visible {
  opacity: 1;
}

.ag h2 {
  font-size: 2rem;
  font-weight: unset;
}
.ag h2 {
  font-size: 1.6rem;
  font-weight: unset;
}
.ag h3 {
  font-size: 1.3rem;
  font-weight: unset;
}
.ag h4 {
  font-size: 1.1rem;
  font-weight: unset;
}
.ag h5 {
  font-size: .9rem;
  font-weight: unset;
}
.ag h6 {
  font-size: .8rem;
  font-weight: unset;
}



@media screen and (max-width: 600px) {
  :root {
    --menu-c: 20vw;
  }
  html {
    font-size: 15px;
  }
  .desktop {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .action-btn {
    right: var(--padding);
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 600px) {
  .desktop {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@media screen and (min-width: 601px) {
  .mobile {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 300px) {
  html {
    font-size: 10px;
    color: var(--title-text-5);
  }
}

